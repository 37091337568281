import ApiService from '@/services/core/api.service'

const TemplateService = {
  async get (query) {
    return ApiService.get(`admin/templated?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/templated', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/templated/${payload.id}`, payload)
  }

}

export default TemplateService
