import ApiService from '@/services/core/api.service'

const DashboardService = {
  async getTypes (query = '') {
    return ApiService.get(`user/dashboards/types?${query}`)
  },

  async getCategories (query = '') {
    return ApiService.get(`user/dashboards/categories?${query}`)
  }
}

export default DashboardService
