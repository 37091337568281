import ApiService from '@/services/core/api.service'

const SupplierService = {
  async get (query) {
    return ApiService.get(`admin/suppliers?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/suppliers', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/suppliers/${payload.id}`, payload)
  }
}

export default SupplierService
