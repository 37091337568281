function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/admin/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const adminRoutes = [
  {
    path: '/admin/dashboard',
    component: view('DashboardTotalItems'),
    name: 'admin.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/statistics/total-costs',
    component: view('statistics/StatisticsTotalCosts'),
    name: 'admin.statistics.total-costs',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Total Costs',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/statistics/status-total-costs',
    component: view('statistics/StatisticsStatusTotalCosts'),
    name: 'admin.statistics.status-total-costs',
    meta: {
      breadcrumb: [
        {
          text: 'Statistics'
        },
        {
          text: 'Total Costs per Status',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/profiles/units',
    component: view('profiles/Units'),
    name: 'admin.units',
    meta: {
      breadcrumb: [
        {
          text: 'Profiles'
        },
        {
          text: 'Units',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/profiles/departments',
    component: view('profiles/Departments'),
    name: 'admin.departments',
    meta: {
      breadcrumb: [
        {
          text: 'Profiles'
        },
        {
          text: 'Departments',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/profiles/positions',
    component: view('profiles/Positions'),
    name: 'admin.positions',
    meta: {
      breadcrumb: [
        {
          text: 'Profiles'
        },
        {
          text: 'Positions',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/profiles/employees',
    component: view('profiles/Employees'),
    name: 'admin.employees',
    meta: {
      breadcrumb: [
        {
          text: 'Profiles'
        },
        {
          text: 'Employee',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/profiles/user-accounts',
    component: view('profiles/UserAccounts'),
    name: 'admin.user-accounts',
    meta: {
      breadcrumb: [
        {
          text: 'Profiles'
        },
        {
          text: 'User Accounts',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/assets/types',
    component: view('maintenances/Types'),
    name: 'admin.types',
    meta: {
      breadcrumb: [
        {
          text: 'Assets'
        },
        {
          text: 'Types',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/assets/categories',
    component: view('maintenances/Categories'),
    name: 'admin.categories',
    meta: {
      breadcrumb: [
        {
          text: 'Assets'
        },
        {
          text: 'Categories',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/assets/statuses',
    component: view('maintenances/Statuses'),
    name: 'admin.statuses',
    meta: {
      breadcrumb: [
        {
          text: 'Assets'
        },
        {
          text: 'Statuses',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/assets/suppliers',
    component: view('maintenances/Suppliers'),
    name: 'admin.suppliers',
    meta: {
      breadcrumb: [
        {
          text: 'Assets'
        },
        {
          text: 'Suppliers',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/assets/brands',
    component: view('maintenances/Brands'),
    name: 'admin.brands',
    meta: {
      breadcrumb: [
        {
          text: 'Assets'
        },
        {
          text: 'Brands',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/assets/templates',
    component: view('maintenances/Templates'),
    name: 'admin.templates',
    meta: {
      breadcrumb: [
        {
          text: 'Assets'
        },
        {
          text: 'Templates',
          active: true
        }
      ]
    }
  },
  // {
  //   path: '/admin/inventories/templated-items',
  //   component: view('TemplatedItems'),
  //   name: 'admin.templated-items',
  //   meta: {
  //     breadcrumb: [
  //       {
  //         text: 'Inventories'
  //       },
  //       {
  //         text: 'Templated Items',
  //         active: true
  //       }
  //     ]
  //   }
  // },
  {
    path: '/admin/inventories/items',
    component: view('inventories/Items'),
    name: 'admin.inventories.items',
    meta: {
      breadcrumb: [
        {
          text: 'Inventories'
        },
        {
          text: 'Items',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/inventories/peripherals/item-details',
    component: view('inventories/ItemDetails'),
    name: 'admin.inventories.peripherals.item-details',
    meta: {
      breadcrumb: [
        {
          text: 'Inventories'
        },
        {
          text: 'Peripherals'
        },
        {
          text: 'Item Details',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/inventories/peripherals/swap',
    component: view('inventories/SwapItemDetails'),
    name: 'admin.inventories.peripherals.swap',
    meta: {
      breadcrumb: [
        {
          text: 'Inventories'
        },
        {
          text: 'Peripherals'
        },
        {
          text: 'Swap',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/inventories/peripherals/attach-detach',
    component: view('inventories/AttachDetachItemDetails'),
    name: 'admin.inventories.peripherals.attach-detach',
    meta: {
      breadcrumb: [
        {
          text: 'Inventories'
        },
        {
          text: 'Peripherals'
        },
        {
          text: 'Attach or Detach',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/inventories/peripherals/assign-return',
    component: view('inventories/AssignReturnItemDetails'),
    name: 'admin.inventories.peripherals.assign-return',
    meta: {
      breadcrumb: [
        {
          text: 'Inventories'
        },
        {
          text: 'Peripherals'
        },
        {
          text: 'Assign or Return',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/inventories/upload-templated-items',
    component: view('inventories/TemplatedItems'),
    name: 'admin.inventories.upload-templated-items',
    meta: {
      breadcrumb: [
        {
          text: 'Inventories'
        },
        {
          text: 'Templated Items',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/inventory-histories/items',
    component: view('histories/ItemHistories'),
    name: 'admin.inventory-histories.items',
    meta: {
      breadcrumb: [
        {
          text: 'Inventory Histories'
        },
        {
          text: 'Items',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/inventory-histories/peripherals',
    component: view('histories/ItemDetailHistories'),
    name: 'admin.inventory-histories.peripherals',
    meta: {
      breadcrumb: [
        {
          text: 'Inventory Histories'
        },
        {
          text: 'Peripherals',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/borrowings',
    component: view('borrowing/Borrowings'),
    name: 'admin.borrowings',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Borrowings',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/borrowing-reservations',
    component: view('borrowing/BorrowingReservations'),
    name: 'admin.borrowing-reservations',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Borrowing Reservations',
          active: true
        }
      ]
    }
  },
  {
    path: '/admin/borrowing-histories',
    component: view('borrowing/BorrowingHistories'),
    name: 'admin.borrowing-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Borrowing Histories',
          active: true
        }
      ]
    }
  }
]

export default adminRoutes
