import ApiService from '@/services/core/api.service'

const EmployeeService = {
  async get (query) {
    return ApiService.get(`admin/employees?${query}`)
  },

  async getItems (query) {
    return ApiService.get(`admin/assigned/items?${query}`)
  },

  async getItemDetails (query) {
    return ApiService.get(`admin/assigned/item-details?${query}`)
  },

  async getIssuances (query) {
    return ApiService.get(`admin/assigned/issuances?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/employees', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/employees/${payload.id}`, payload)
  },

  async assign (payload) {
    return ApiService.put(`admin/employees/assign-items/${payload.id}`, payload)
  },

  async issue (payload) {
    return ApiService.put(`admin/employees/issue-items/${payload.id}`, payload)
  },

  async return (payload) {
    return ApiService.put(`admin/employees/return-items/${payload.id}`, payload)
  }
}

export default EmployeeService
