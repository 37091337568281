import ApiService from '@/services/core/api.service'

const StatusService = {
  async get (query) {
    return ApiService.get(`admin/statuses?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/statuses', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/statuses/${payload.id}`, payload)
  }
}

export default StatusService
