import ApiService from '@/services/core/api.service'

const ItemDetailService = {
  async get (query) {
    return ApiService.get(`admin/item-details?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/item-details', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/item-details/${payload.id}`, payload)
  },

  async status (payload) {
    return ApiService.put(`admin/item-details/status/${payload.id}`, payload)
  },

  async attach (payload) {
    return ApiService.put(`admin/item-details/attach/${payload.id}`, payload)
  },

  async detach (payload) {
    return ApiService.put(`admin/item-details/detach/${payload.id}`, payload)
  },

  async swap (payload) {
    return ApiService.post('admin/item-details/swap', payload)
  },

  async assign (payload) {
    return ApiService.put(`admin/item-details/assign/${payload.id}`, payload)
  },

  async issue (payload) {
    return ApiService.put(`admin/item-details/issue/${payload.id}`, payload)
  },

  async return (payload) {
    return ApiService.put(`admin/item-details/return/${payload.id}`, payload)
  }

}

export default ItemDetailService
