import ApiService from '@/services/core/api.service'

const ItemHistoryService = {
  async getItemHistories (query) {
    return ApiService.get(`admin/histories/items?${query}`)
  },

  async getItemDetailHistories (query) {
    return ApiService.get(`admin/histories/item-details?${query}`)
  }
}

export default ItemHistoryService
