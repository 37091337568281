import ApiService from '@/services/core/api.service'

const UserService = {
  async get (query) {
    return ApiService.get(`admin/users?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/users', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/users/${payload.id}`, payload)
  }
}

export default UserService
