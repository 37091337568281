import ApiService from '@/services/core/api.service'

const ListService = {
  async getUnits () {
    return ApiService.get('shared/lists/units')
  },

  async getDepartments (query = '') {
    return ApiService.get(`shared/lists/departments?${query}`)
  },

  async getPositions (query = '') {
    return ApiService.get(`shared/lists/positions?${query}`)
  },

  async getEmployees (query = '') {
    return ApiService.get(`shared/lists/employees?${query}`)
  },

  async getTypes (query = '') {
    return ApiService.get(`shared/lists/types?${query}`)
  },

  async getCategories (query = '') {
    return ApiService.get(`shared/lists/categories?${query}`)
  },

  async getTemplates (query = '') {
    return ApiService.get(`shared/lists/templates?${query}`)
  },

  async getTemplateComposition (query = '') {
    return ApiService.get(`shared/template-composition?${query}`)
  },

  async getTemplateOnEmployeeBorrowing (query = '') {
    return ApiService.get(`shared/lists/templates-employee-borrowing?${query}`)
  },

  async getStatuses () {
    return ApiService.get('shared/lists/statuses')
  },

  async getSuppliers () {
    return ApiService.get('shared/lists/suppliers')
  },

  async getBrands () {
    return ApiService.get('shared/lists/brands')
  },

  async getItems (query = '') {
    return ApiService.get(`shared/lists/items?${query}`)
  },

  async getBorrowingReservableItems (query = '') {
    return ApiService.get(`shared/lists/reservable-items?${query}`)
  },

  async getAssignableItems (query = '') {
    return ApiService.get(`shared/lists/assignable-items?${query}`)
  },

  async getItemDetails (query = '') {
    return ApiService.get(`shared/lists/item-details?${query}`)
  },

  async getAssignableItemDetails (query = '') {
    return ApiService.get(`shared/lists/assignable-item-details?${query}`)
  },

  async getAttachableItemDetails (query = '') {
    return ApiService.get(`shared/lists/attachable-item-details?${query}`)
  },

  async getSwappableItemDetails (query = '') {
    return ApiService.get(`shared/lists/swappable-item-details?${query}`)
  },

  async getUsers () {
    return ApiService.get('shared/lists/users')
  }
}

export default ListService
