import ApiService from '@/services/core/api.service'

const StatisticsService = {
  async getTypeCosts (query = '') {
    return ApiService.get(`admin/statistics/type-costs?${query}`)
  },

  async getCategoryCosts (query = '') {
    return ApiService.get(`admin/statistics/category-costs?${query}`)
  },

  async getTypeStatusCosts (query = '') {
    return ApiService.get(`admin/statistics/type-status-costs?${query}`)
  },

  async getCategoryStatusCosts (query = '') {
    return ApiService.get(`admin/statistics/category-status-costs?${query}`)
  },

  async getStatuses (query = '') {
    return ApiService.get(`admin/statistics/statuses?${query}`)
  }
}

export default StatisticsService
