import ApiService from '@/services/core/api.service'

const UserBorrowingService = {
  async get (query = '') {
    return ApiService.get(`admin/user-borrowings?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/user-borrowings', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/user-borrowings/${payload.id}`, payload)
  }
}

export default UserBorrowingService
