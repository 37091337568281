function view (path) {
  return () => import(/* webpackChunkName: '' */ '@/views/user/' + path).then(m => m.default || m) // eslint-disable-line prefer-template
}

const userRoutes = [
  {
    path: '/user/dashboard',
    component: view('Dashboard'),
    name: 'user.dashboard',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Dashboard',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/items',
    component: view('Items'),
    name: 'user.items',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Items',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/item-details',
    component: view('ItemDetails'),
    name: 'user.item-details',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Item Details',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/borrowings',
    component: view('borrowing/Borrowings'),
    name: 'user.borrowings',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Borrowings',
          active: true
        }
      ]
    }
  },
  {
    path: '/user/borrowing-histories',
    component: view('borrowing/BorrowingHistories'),
    name: 'user.borrowing-histories',
    meta: {
      breadcrumb: [
        {
          text: 'Home'
        },
        {
          text: 'Borrowing Histories',
          active: true
        }
      ]
    }
  }
]

export default userRoutes
