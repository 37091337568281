import ApiService from '@/services/core/api.service'

const AdminBorrowingItemDetailService = {
  async get (query = '') {
    return ApiService.get(`admin/borrowing-item-details?${query}`)
  },

  async show (id, query = '') {
    return ApiService.get(`admin/borrowing-item-details/${id}?${query}`)
  },

  async post (payload) {
    return ApiService.post('admin/borrowing-item-details', payload)
  },

  async put (payload) {
    return ApiService.put(`admin/borrowing-item-details/${payload.id}`, payload)
  }
}

export default AdminBorrowingItemDetailService
