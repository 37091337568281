import ApiService from '@/services/core/api.service'

const BorrowingHistoryService = {

  async getBorrowings (query = '') {
    return ApiService.get(`admin/borrowing-histories/borrowings?${query}`)
  },

  async getBorrowingItems (query = '') {
    return ApiService.get(`admin/borrowing-histories/items?${query}`)
  },

  async getBorrowingItemDetails (query = '') {
    return ApiService.get(`admin/borrowing-histories/item-details?${query}`)
  }

}

export default BorrowingHistoryService
